import React from "react";
import Home from "./Home";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import Error from "./Error";
import '../src/stylesheet.css';
import '../src/responsive.css';
import '../src/font/stylesheet.css'
import ScrollToTop from "./ScrollToTop";
import { Aboutus } from "./Aboutus";
import { Project_details } from "./Project-details";
import { Projects } from "./Projects";
import { Media } from "./Media";
import { Events } from "./Events";
import Eventdetail from "./Eventdetail";
import { Press } from "./Press";
import { Contact } from "./Contact";
import { Career } from "./Career";
import { Privacy } from "./Privacy";
import { Refund } from "./Refund";
import { Disclaimer } from "./Disclaimer";
import { Construction } from "./Construction";

const App = () => {
  return (
    <>
    <Router>
    <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/about-ameya" component={Aboutus}></Route>
        <Route exact path="/projects" component={Projects}></Route>
        <Route exact path="/projects/:slug" component={Project_details}></Route>
        <Route exact path="/channel-partner-event" component={Eventdetail}></Route>
        <Route exact path="/contact-us" component={Contact}></Route>
        <Route exact path="/career" component={Career}></Route>
        <Route exact path="/privacy-policy" component={Privacy}></Route>
        <Route exact path="/refund-policy" component={Refund}></Route>
        <Route exact path="/disclaimer" component={Disclaimer}></Route>
        <Route exact path="/projects/construction-updates/:slug" component={Construction}></Route>        
        <Route exact path="/projects/construction-updates/:slug/:slug2/" component={Construction}></Route>        
        <Route component={Error}></Route>
      </Switch>
      </Router>
    </>
  );
};

export default App;
