import React from 'react'
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import Locationmap from './images/loc-img.jpg'
import Locationmapb from './images/locationmap.jpg'

function Locationpop({locationmapdata}) {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="Gallerybox">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                <a href={locationmapdata}>
                <img src={locationmapdata} />
                <span onClick={() => this.setState({ isOpen: true })} >
                Click to view location map
        </span>
                                   
                </a>
            </LightGallery>
        </div>
    );
}
export default Locationpop;