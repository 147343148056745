import React , {useState, useEffect}from 'react'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Locationpop from './Locationpop'
import Gallerybox from './Gallerybox';
import Slider from "react-slick";
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';


var decodeHTML = function (html) {
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
};

function createMarkup(content) {
    return {__html: decodeHTML(content)};
  }


export const Project_details = ({match}) => {

    


    // API work start

    const slug = match.params.slug;
    const [pagedata, setPagedata] = useState([]);

    let history = useHistory();

    const [data, setData] = useState([]);
    useEffect(() => {

        let mounted = true;

        // Aos.init({ duration: 2000 });

        const loadData = async () => {
            const response = await axios.get(`https://ameyagroup.in/ameya_new_website/api/projects_details.php?url=${slug}`)
            if (mounted) {
                setPagedata(response.data);
                // setData(response.data.products.accordions);
            }

        }

        loadData();

        return () => {
            mounted = false;
        }

    }, []);
    
    console.log(pagedata);
    if (pagedata.length == 0) {
        console.log('no data');
        return null;
    }
var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 600,
    arrows: true,
    autoplaySpeed:8000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

    // API work end

  return (
    <>
        <Header />
        <div className='project_head'>
            <div className='projectrow justify-content-between'>
                <div className='projectbanner' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                    {pagedata.project.probannerdesk ? 
                    <img src={pagedata.project.probannerdesk} />
                    : null}
                </div>
                {/* Condition Start */}
                {pagedata.project.status=='ongoing' ?
                <div className='enquireform' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                    <div className='proenquire'>
                    <img src={pagedata.project.logo} />
                        <h2>Register your interest</h2>
                <ul className='form d-flex flex-wrap justify-content-between'>
                  <li><input type='text' placeholder='Name*' name='name' required/></li>
                  <li><input type='tel' placeholder='Mobile No*' name='mobile' required/></li>        
                  <li><input type='submit' value='submit' className='btnfill button-2'/></li>
                </ul>
                {pagedata.project.rerano ? 
                <p>{pagedata.project.rerano}</p>
                : null}
                </div>
                </div>

               
                : null}
                {/* Condition End */}
            </div>
        </div>
        <div className="breadcrum">
        <div className='container'>
          <div className='row'>
            <div className='breadcumtab'>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/projects'>Our Projects</Link></li>
                <li>{pagedata.project.title}</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
        <div className='overview'>
            <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                            <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                                <h2>OVERVIEW</h2>
                            </div>
                            <div className='overviewrow'>    
                            <div className='projectcont'>
                            <h1 data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">{pagedata.project.overview_heading}</h1>
                                <p data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300" dangerouslySetInnerHTML={createMarkup(pagedata.project.overview)}></p>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        {pagedata.project.amenities ?
        <div className='amenities'>
            <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                    <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                            <h2>{pagedata.project.amenities_heading}</h2>
                        </div>
                    </div>
                </div>
            </div>
        <div className='amenitiesrow'>
            <div className='row'>
                <div className='container'>                      
                <div className='amenitiesstn0'>
                <ul>
                {pagedata.project.amenities.map(data => {
                    return <li data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300"><span><img src={data.icon} /></span><p>{data.title}</p></li>
                })}
                </ul>
            </div>            
            </div>
                </div>
        </div>
        </div>
        : null}
        {pagedata.project.mix ?
         <div className='themix'>
            <div className='container'>
                <div className='row'>
                <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                            <h2>The Mix</h2>
                        </div>
                    <div className='themixstn d-flex justify-content-between' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                      {/* Static Slider */}
                      <Slider {...settings}>
                        {/* Loop Start */}
                        {pagedata.project.mix.map(mixData => (
      <div>
      <div className='mixslide'>
      <div className='themixbox'>
                            <div className='themiximg'>
                                <img src={mixData.img} />
                                <div className='themixcont' dangerouslySetInnerHTML={createMarkup(mixData.descp)}>
                            </div>
                            </div>                           
                            <h4 dangerouslySetInnerHTML={{__html: mixData.title}}></h4>
                        </div>
        </div>
      </div>
                        ))}
      {/* Loop End */}
    </Slider>
                    </div>
                </div>
            </div>
        </div>
        : null}
        {(pagedata.project.locationimg!='') || (pagedata.project.locationmap!='') ? 
        <div className='location'>
            <div className='container'>
                <div className='row'>
                <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                            <h2>Location</h2>
                        </div>
                        <div className='col-md-12 justify-content-center align-items-center d-flex' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                                {pagedata.project.locationimg ?
                                <div className='locationimg'>
                                    <img src={pagedata.project.locationimg} />
                                </div>
                                : null}
                                {pagedata.project.locationmap ? 
                                <div className='locationmap'>
                                    <div className='locationmapview'>
                                        <Locationpop locationmapdata={pagedata.project.locationmap}/>
                                    </div>
                                </div>
                            : null}
                        </div>
                </div>
            </div>
        </div>
        : null}
        {pagedata.project.gallery ? 
        <div className='gallery'>
            <div className='container'>
                <div className='row'>
                        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                            <h2>Gallery</h2>
                        </div>
                        <div className='gslider' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                              <Gallerybox galData={pagedata.project.gallery}/>
                        </div>
                    </div>
            </div>
        </div>
        : null}
        {(pagedata.project.ebroucher!='' || pagedata.project.floor_plans!='' || pagedata.project.const_img!=0) ?
        <div className='download'>
            <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                            <h2>Downloads</h2>
                        </div>
                        <div className='highlightbtn'>
                            <ul data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                                {pagedata.project.ebroucher ?
                                <li><a href={pagedata.project.ebroucher} target='_blank'><span>Brochure</span></a></li>
                                : null}
                                {pagedata.project.floor_plans ?
                                <li><a href={pagedata.project.floor_plans} target="_blank"><span>Floor Plans</span></a></li>
                                : null}
                                {pagedata.project.const_img ?
                                <li><Link to={"construction-updates/"+pagedata.project.slug}><span>Construction Updates</span></Link></li>
                                : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : null}
        <Footer />
    </>
  )
}
