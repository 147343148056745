import React, {useState, useEffect} from 'react'
import Header from './components/Header'
import { Link } from 'react-router-dom';
import { Footer } from './components/Footer';
import axios from 'axios';
export const Contact = () => {


  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
       const response = await axios.get('https://ameyagroup.in/ameya_new_website/api/contact_content.php');
       if(mounted){
           setPagedata(response.data);
       }
    }

    loadData();

    return () => {
        mounted = false;
      };
}, []);

console.log(pagedata);

if (pagedata.length===0) {
    console.log('no data');
    return null;
}


  return (
    <>
        <Header />
        <div className='banner'>
        <div className='bannerimg'>
            <img src={pagedata.aboutus.ctbanner} alt='Ameya Contact'/>
        </div>
    </div>
    
    <div className="breadcrum">
        <div className='container'>
          <div className='row'>
            <div className='breadcumtab'>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
    <div className='contact_us'>
        <div className='container'>
          <div className='row'>            
          <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <h2>Contact Us</h2>
              </div>
          </div>
        </div>
      <Footer />
          </div>
    </>
  )
}
