import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

export const Copyright = () => {
  return (
    <>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="copyrightrow d-flex justify-content-between">
              <p>
                &copy; 2022 all rights reserved. Ameya |
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>|
                <NavLink to="/privacy-policy#disclaimer">Disclaimer</NavLink>
              </p>
              <p>
                <a
                  href="https://www.triverseadvertising.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  design : triverse
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
