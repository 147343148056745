import React from 'react'
import { Footer } from './components/Footer'
import Header from './components/Header'

export const Disclaimer = () => {
  return (
    <>
        <Header />
        <section>
            <div className='container'>
                <div className='web-container'>
                    <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                        <h2>Disclaimer</h2>
                    </div>
                    <div className='webcontent' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                    <p>This is a general specification with no legal boundations for Builders, Promoters and Investers. Each project has it's own amenities and specifications.</p>
<p>Further, in the interest of maintaining high standards, the Company/Architect reserves the right to modify the details/specifications/features shown and presented here. Further, marble and other things are subject to natural variations in texture and colour.</p>
<p>Ameya Group reserves the right to make changes or updates to the Projects or Programs described in this Website at any time without notice.</p>
<p>Price - Prices can be changed anytime without prior notice.</p>
<p>Vacancy - The availability can be changed anytime without prior notice.</p>

                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}
