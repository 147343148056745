import React, {useState, useEffect} from 'react'
import Slider from "react-slick";
import { Footer } from './components/Footer'
import Header from './components/Header'
import { Link } from 'react-router-dom';
import axios from 'axios';

export const Aboutus = () => {

  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
       const response = await axios.get('https://ameyagroup.in/ameya_new_website/api/about.php');
       if(mounted){
           setPagedata(response.data);
       }
    }

    loadData();

    return () => {
        mounted = false;
      };
}, []);

console.log(pagedata);
if (pagedata.length===0) {
    console.log('no data');
    return null;
}

  var settings = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 600,
    autoplaySpeed:5000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
  return (
    <>
      <Header />
      <div className='banner'>
        <div className='bannerimg'>
        <img src={pagedata.aboutus.banner} />
        </div>
    </div>
    <div className="breadcrum">
        <div className='container'>
          <div className='row'>
            <div className='breadcumtab'>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li>About Ameya</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
    <div className='aboutameya innerabt position-relative'>
      <div className='container'>
        <div className='row'>
        <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <h2>About Us</h2>
              </div>
          <div className='aboutcont d-flex justify-content-between'>
            <div className='aboutleft position-relative p-0'>
            <h2 className='position-relative' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">{pagedata.aboutus.title}</h2>
            </div>
            <div className='aboutright p-0' data-aos="fade-in" data-aos-offset="400" data-aos-duration="800" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300" dangerouslySetInnerHTML={{__html:pagedata.aboutus.ldesc}}>
            </div>
          </div>
        </div>
      </div>
    </div>       
    <div className='record'>
      <div className='container'>
          <h2 className='subheading' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">Proven track record</h2>
          <div className='recordcount'>
          {pagedata.aboutus.num_title1 ? 
           
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title1}</span>
                <p>{pagedata.aboutus.num_desc1}</p>
              </div>
           
            : ''}
            {pagedata.aboutus.num_title2 ? 
           
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title2}</span>
                <p>{pagedata.aboutus.num_desc2}</p>
              </div>
           
            : ''}
            {pagedata.aboutus.num_title3 ? 
            
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title3}</span>
                <p>{pagedata.aboutus.num_desc3}</p>
              </div>
            
            : ''}
            {pagedata.aboutus.num_title4!=0 ? 
           
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title4}</span>
                <p>{pagedata.aboutus.num_desc4}</p>
              </div>
            
            : ''}
            {pagedata.aboutus.num_title5!=0 ? 
           
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title5}</span>
                <p>{pagedata.aboutus.num_desc5}</p>
              </div>
            
            : ''}
            {pagedata.aboutus.num_title6!=0 ? 
            
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title6}</span>
                <p>{pagedata.aboutus.num_desc6}</p>
              </div>
            
            : ''}
            {pagedata.aboutus.num_title7!=0 ? 
            
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title7}</span>
                <p>{pagedata.aboutus.num_desc7}</p>
              </div>
            
            : ''}
            {pagedata.aboutus.num_title8!=0 ? 
            
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title8}</span>
                <p>{pagedata.aboutus.num_desc8}</p>
              </div>
            
            : ''}
            {pagedata.aboutus.num_title9!=0 ? 
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title9}</span>
                <p>{pagedata.aboutus.num_desc9}</p>
              </div>
            : ''}
            {pagedata.aboutus.num_title10!=0 ? 
              <div className='recordbox' data-aos="fade" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <span>{pagedata.aboutus.num_title10}</span>
                <p>{pagedata.aboutus.num_desc10}</p>
              </div>
            : ''}
            </div>
      </div>
    </div>
    {/* Loop Start */}
    {pagedata.aboutus.brands ?
    <div className="sapphire">
        <div className='container'>
          <div className='row'>
            <div className='sappban text-center'>
            <h2 className='subheading' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">Leading Brands on Board at the Sapphire Chain of Bazaars</h2>
              <ul>
                { pagedata.aboutus.brands.map(brandsdata => {
                return <li data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-delay="200" data-aos-easing="ease-in-sine"><img src={brandsdata.icon} /></li>
                })
                }
              </ul>
            </div>
          </div>
        </div>
    </div>
    : null}
    {/* Loop End */}
    <div className='vmsion position-relative'>
      <div className='container'>
          <div className='row'>
              <div className='visionstn'>
              <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                      <h2>Our Ethos</h2>
                    </div>
                    <div className='vmcolum d-flex'>
                      <div className='col-md-6 col-xs-12' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                        <h3>Vision</h3>
                        <p>{pagedata.aboutus.ethos_v_desc}</p>
                      </div>
                      <div className='col-md-6 col-xs-12' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                        <h3>Mission</h3>
                        <p>{pagedata.aboutus.ethos_m_desc}</p>
                      </div>
                    </div>
                    <div className='valuecolum d-flex col-12'>
                      <div className='col-md-12 col-xs-12' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                        <h3 className='text-center'>Values</h3>
                        <div className='valueban' style={{backgroundImage: `url(${pagedata.aboutus.ethos_pic})`}}>
                          <div className='row'>
                          {pagedata.aboutus.ethos_val_title1!='' ? 
                          <div className='col-md-6 col-xs-12'>
                            <h4>{pagedata.aboutus.ethos_val_title1}</h4>
                            <p>{pagedata.aboutus.ethos_val_desc1}</p>
                          </div>
                          : null}
                          {pagedata.aboutus.ethos_val_title2!='' ? 
                          <div className='col-md-6 col-xs-12'>
                            <h4>{pagedata.aboutus.ethos_val_title2}</h4>
                            <p>{pagedata.aboutus.ethos_val_desc2}</p>
                          </div>
                          : null}
                          {pagedata.aboutus.ethos_val_title3!='' ? 
                          <div className='col-md-6 col-xs-12'>
                            <h4>{pagedata.aboutus.ethos_val_title3}</h4>
                            <p>{pagedata.aboutus.ethos_val_desc3}</p>
                          </div>
                          : null}
                          {pagedata.aboutus.ethos_val_title4!='' ? 
                          <div className='col-md-6 col-xs-12'>
                            <h4>{pagedata.aboutus.ethos_val_title4}</h4>
                            <p>{pagedata.aboutus.ethos_val_desc4}</p>
                          </div>
                          : null}
                        </div>
                        </div>
                      </div>
                    </div>
              </div>
          </div>
      </div>
    </div>
    <div className='journey'>
            <div className='container'>
              <div className='row'>
                  <div className='title'>
                    <h2 data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">Leadership</h2>
                  </div>
                  <div className='journeryslider' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
            <Slider {...settings}>
              {/* Loop Start */}
              {pagedata.aboutus.leadership_title1!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title1}</h3>
                <span>{pagedata.aboutus.leadership_des1}</span>
               <p>{pagedata.aboutus.leadership_desc1}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title2!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title2}</h3>
                <span>{pagedata.aboutus.leadership_des2}</span>
               <p>{pagedata.aboutus.leadership_desc2}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title3!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title3}</h3>
                <span>{pagedata.aboutus.leadership_des3}</span>
               <p>{pagedata.aboutus.leadership_desc3}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title4!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title4}</h3>
                <span>{pagedata.aboutus.leadership_des4}</span>
               <p>{pagedata.aboutus.leadership_desc4}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title5!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title5}</h3>
                <span>{pagedata.aboutus.leadership_des5}</span>
               <p>{pagedata.aboutus.leadership_desc5}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title6!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title6}</h3>
                <span>{pagedata.aboutus.leadership_des6}</span>
               <p>{pagedata.aboutus.leadership_desc6}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title7!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title7}</h3>
                <span>{pagedata.aboutus.leadership_des7}</span>
               <p>{pagedata.aboutus.leadership_desc7}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title8!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title8}</h3>
                <span>{pagedata.aboutus.leadership_des8}</span>
               <p>{pagedata.aboutus.leadership_desc8}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title9!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title9}</h3>
                <span>{pagedata.aboutus.leadership_des9}</span>
               <p>{pagedata.aboutus.leadership_desc9}</p>
            </div>
          </div>
        </div>
        : null}
        {pagedata.aboutus.leadership_title10!='' ? 
        <div>
          <div className='journeyrow d-flex justify-content-between'>
            <div className='journeycont'>
                <h3>{pagedata.aboutus.leadership_title10}</h3>
                <span>{pagedata.aboutus.leadership_des10}</span>
               <p>{pagedata.aboutus.leadership_desc10}</p>
            </div>
          </div>
        </div>
        : null}
        
        {/* Loop End */}
    </Slider>
    </div>
              </div>
            </div>
    </div>
          <div className='ourethos'>
              <div className='container'>
                <div className='row'>
                      <h2 className='subheading text-center'>Ameya’s Approach to <br/>Community Shopping</h2>
                    <div className='ourethosrow d-flex justify-content-between'>
                      <div className='ourethosimg' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                        <img src={pagedata.aboutus.approach_pic} />
                      </div>
                      <div className='ourthoscont' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="300">
                        <div className='ethoscontrow'>
                        <h2>{pagedata.aboutus.approach_title1}</h2>
                          <p>{pagedata.aboutus.approach_desc1}</p>
                          <h2>{pagedata.aboutus.approach_title2}</h2>
                          <p>{pagedata.aboutus.approach_desc2}</p>
                          <h2>{pagedata.aboutus.approach_title3}</h2>
                          <p>{pagedata.aboutus.approach_desc3}</p>
                          <h2>{pagedata.aboutus.approach_title4}</h2>
                          <p>{pagedata.aboutus.approach_desc4}</p>
                          <h2>{pagedata.aboutus.approach_title5}</h2>
                          <p>{pagedata.aboutus.approach_desc5}</p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>  
      <Footer />
    </>
  )
}
