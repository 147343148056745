import React from 'react'
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import Header from './components/Header';
import { Footer } from './components/Footer';
import { Link } from 'react-router-dom';

function Eventdetail() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <>
        <Header />
        <div className="breadcrum">
        <div className='container'>
          <div className='row'>
            <div className='breadcumtab'>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/events'>Events</Link></li>
                <li>Events</li>                
              </ul>
            </div>
          </div>
        </div>
    </div>
        <div className='event_detail'>
            <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                        <div className='title'>
                            <h2>Channel Partner Event</h2>
                        </div>
        <div className="Gallerybox">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                <a href="https://www.ssgroup-india.com/images//15924895699cc5dfb4-49d2-466a-af7e-3853ec975e2a.jpg">
                    <img src="https://www.ssgroup-india.com/images/20139590639cc5dfb4-49d2-466a-af7e-3853ec975e2a.jpg" alt="img" />
                    <div class="lightcont">
                                <h4>Channel Partner Event</h4>
                                </div>
                    
                </a>
                <a href="https://www.ssgroup-india.com/images//1363159477f7877c79-3d15-4bdc-9fcd-7af852358fa6%20(1).jpg">
                    <img src="https://www.ssgroup-india.com/images/1278981963f7877c79-3d15-4bdc-9fcd-7af852358fa6%20(1).jpg" alt="img" />
                    <div class="lightcont">
                                <h4>Channel Partner Event</h4>
                                </div>
                    
                </a>
                <a href="https://www.ssgroup-india.com/images//1060022229WhatsApp%20Image%202022-03-14%20at%202.31.08%20PM.jpeg">
                    <img src="https://www.ssgroup-india.com/images/1308997301WhatsApp%20Image%202022-03-14%20at%202.31.08%20PM.jpeg" alt="img" />
                    <div class="lightcont">
                                <h4>Channel Partner Event</h4>
                                </div>
                    
                </a>
            </LightGallery>
            
        </div>
        
        </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}
export default Eventdetail;