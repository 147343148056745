import React, { useState, useEffect } from "react";
import { Copyright } from "./Copyright";
import axios from "axios";

var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Footer = () => {
  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(
        "https://ameyagroup.in/ameya_new_website/api/contact_content.php"
      );
      if (mounted) {
        setPagedata(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);

  if (pagedata.length === 0) {
    console.log("no data");
    return null;
  }

  return (
    <>
      <footer>
        {pagedata.contact ? (
          <div
            className="footer"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
            data-aos-delay="200"
          >
            <div className="container">
              <div className="row">
                <div className="ftrrow d-flex justify-content-between">
                  {pagedata.contact.map((careerdesc) => {
                    return (
                      <div className="ftr">
                        <span>{careerdesc.heading}</span>
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            careerdesc.description
                          )}
                        ></div>
                      </div>
                    );
                  })}
                </div>
                <div className="racall">
                  <div className="racallrow">
                    <div className="title">
                      <span className="titleSpan">Enquire</span>
                    </div>
                    <form>
                      <ul className="form d-flex flex-wrap justify-content-between">
                        <li>
                          <input
                            type="text"
                            placeholder="Name*"
                            name="name"
                            required
                          />
                        </li>
                        <li>
                          <input
                            type="email"
                            placeholder="Email*"
                            name="email"
                            required
                          />
                        </li>
                        <li>
                          <input
                            type="tel"
                            placeholder="Mobile No*"
                            name="mobile"
                            required
                          />
                        </li>
                        <li>
                          <button className="btn-3">
                            <span>Submit</span>
                          </button>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <Copyright />
      </footer>
    </>
  );
};
