import React, {useState, useEffect} from 'react'
import { Footer } from './components/Footer'
import Header from './components/Header'
import { Accordion, AccordionItem } from 'react-light-accordion';
import Careerbanner from './images/career.jpg'
import { Link } from 'react-router-dom';
import axios from 'axios';

export const Career = () => {

  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
       const response = await axios.get('https://ameyagroup.in/ameya_new_website/api/career.php');
       if(mounted){
           setPagedata(response.data);
       }
    }

    loadData();

    return () => {
        mounted = false;
      };
}, []);

console.log(pagedata);

if (pagedata.length===0) {
    console.log('no data');
    return null;
}
  return (
    <>
    <Header />
    <div className='banner'>
        <div className='bannerimg'>
            <img src={pagedata.aboutus.crbanner} />
        </div>
    </div>
    
    <div className="breadcrum">
        <div className='container'>
          <div className='row'>
            <div className='breadcumtab'>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li>Career</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
    {pagedata.career?
      <div className='nricorner bg-white'>
        <div className='container'>
          <div className='row'>
          {pagedata.career.map(careerdesc =>{
            return <div className='web-container'>
            <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <h2>{careerdesc.heading}</h2>
              </div>
                <p data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100" dangerouslySetInnerHTML={{__html: careerdesc.description}}></p>
              </div>
          })}
          </div>
        </div>
      </div>
      : null}
      {pagedata.careerjobs ?
      <div className='nriaccordian'>
        <div className='container'>
          <div className='web-container'>
          <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                <h2>CURRENT OPENING</h2>
              </div>
        <div>
          <div className='currentopening' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
      <Accordion atomic={true}>
      {pagedata.careerjobs.map(jobs => {
      <AccordionItem title={jobs.title}>
        <div className='nricont'>
        <ul>
                                  <li><i class="fa fa-suitcase"></i><span>B.E. (Civil) with minimum 15-20 years’ experience of independently planning, coordinating, executing, and spearheading construction of multidisciplinary real estate projects. Candidate shall be independently responsible for the project assigned.</span></li>
                                  <li><i class="fa fa-map-marker"></i><span>Gurugram</span></li>
                                </ul>
                                <p>Please send your updated resume, mentioning your current CTC, expected salary and notice period to <a href="career@ameyagroup.in">career@ameyagroup.in</a></p>
      </div>
      </AccordionItem>
      })
    }
    </Accordion>
    </div>
  </div>
  </div>
        </div>
      </div>
      : null}
    <Footer />
    </>
  )
}
