import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css"
import Logo from '../images/ameya.png';

export const Header = () => {
    useEffect(() => {
        Aos.init({duration: 1000});
        window.addEventListener('scroll', changeBackground);             
    
        return () => {
          window.removeEventListener('scroll', changeBackground);
        }
       }, []);
       const canonical = window.location.href; console.log(canonical);
       const [menus, setActive] = useState("false");
          
            const handleToggle = () => {
              setActive(!menus);
            };
            // const [enquirefrm, setActive1] = useState("false");
          
            // const handleToggle1 = () => {
            //   setActive1(!enquirefrm);
            // };
       const [header,setNavbar] = useState(false);
        const changeBackground = () => {
         if(window.scrollY >= 80) {
           setNavbar(true);
         } else {
           setNavbar(false);
         }
        };
  return (
    <header>
      <div className={header ? 'header fixed' : 'header'}>
    <div className='container'>
      <div className='row'>
      <div className='col-4'>
      <div className='logo' data-aos="fade" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
             <Link to='/'><img src={Logo} alt='Ameya'/> </Link>
          </div>
        </div>
        <div className='col-8 a-center d-flex align-items-end justify-content-end'>
        <div className='navbar'>
            <div className={menus ? 'menus' : 'menus active'} >
            <button onClick={handleToggle} className="hemburgur"><span></span><span></span><span></span></button>
              <ul className='d-flex align-items-center toplinks' data-aos="fade" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <li><NavLink to='/' exact >Home</NavLink></li>
                <li><NavLink to='/about-ameya'>About Us</NavLink></li>
                <li><NavLink to='/projects'>Projects</NavLink></li>
                <li><NavLink to='/career'>Career</NavLink></li>
                <li><NavLink to='/contact-us'>Contact Us</NavLink></li>
                <li><button onClick={handleToggle} className="hemburgur"><span></span><span></span><span></span></button></li>
              </ul>
              <div className='menuslide'>
                <div className='togmenu'>
                  <span onClick={handleToggle} className='cross'>X</span>
                <ul>
                <li><NavLink to='/' exact >Home</NavLink></li>
                <li><NavLink to='/about-ameya'>About Us</NavLink></li>
                <li><NavLink to='/projects'>Projects</NavLink></li>
                <li><NavLink to='/career'>Career</NavLink></li>
                <li><NavLink to='/contact-us'>Contact Us</NavLink></li>
              </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
    </div>
  </header>
  )
}
export default Header;